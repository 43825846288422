import styled from 'styled-components';
// @ts-ignore
import backgroundImage from '../../assets/backgroundHome.png';
// @ts-ignore
import fondContactImage from '../../assets/fond-contact.png'

interface RomanNumberProps {
  align: 'left' | 'right';
}
export const HomeContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

export const Background = styled.div`
  background-image: url(${backgroundImage});
  filter: brightness(50%);
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;
export const Content = styled.div`
  position: relative;
  height: 100vh;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  color: white;
  top: 0;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const TitleUpercase = styled.div`
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 29.06px;
  color: rgba(233, 234, 236, 1);
`;

export const DescriptionText = styled.div`
  width: 360px;
  position: absolute;
  top: calc(250px + 187px);
  left: 10%;
  margin-top: 24px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 18.75px;
  color: rgba(255, 255, 255, 1);
`;
export const DescriptionTextRight = styled.div`
  margin-top: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 18.75px;
  color: rgba(255, 255, 255, 1);
`;

export const LayoutText = styled.div`
  width: 600px;
  height: 217px;
  position: absolute;
  top: calc(565px + 187px + 60px);
  left: calc(10% + 100px);
  font-family: 'Roboto', sans-serif;
  font-size: 64px;
  font-weight: 500;
  line-height: 75px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
`;

export const OrangeText = styled.span`
  color: rgba(205, 126, 50, 1);
`;
export const YellowText = styled.span`
  color:rgba(218, 165, 32,1);
`;

export const RightDiv = styled.div`
  position: absolute;
  top: calc(145px + 187px);
  right: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RightDivFirst = styled.div`
  width: 275px;
  border-radius: 40px;
  background-color: rgba(23, 23, 23, 1);
  padding: 32px;
  margin-bottom: 20px;
`;

export const RightDivSecond = styled.div`
  width: 299px;
  border-radius: 40px;
  background-color: rgba(23, 23, 23, 1);
  padding: 16px;
  display: flex;
  align-items: center;
`;

export const TeamText = styled.div`
  font-family: 'Cormorant Garamond', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  color: rgba(233, 234, 236, 1);
  margin-right: 16px;
  margin-left: 24px;
`;

export const WhiteButton = styled.button`
  width: 192px;
  height: 48px;
  position: absolute;
  margin-top: 95px;
  border-radius: 40px;
  border: 1px solid rgba(233, 234, 236, 1);
  background-color: transparent;
  color: rgba(233, 234, 236, 1);
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  left:10%;
  top: calc(280px + 187px);

  &:hover {
    background-color: rgba(218, 165, 32, 1);
    color : black;
    border: 1px solid black;
  }
`;

export const SecureDreamsText = styled.div`
  font-family: 'Roboto', sans-serif;
  position: absolute;
  top: 212px;
  left: 10%;
  width: 445px;
  font-weight: 500;
  font-size: 64px;
  line-height: 75px;
  color: rgba(255, 255, 255, 1);
  height: 100%;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 12px;
`;

export const AvatarImg = styled.img`
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin-right: -24px;
`;

export const CenteredVerticalLine = styled.div`
  width: 1px;
  height: 329px;
  border-left: 1px solid rgba(218, 165, 32, 1);
  position: absolute;
  top: calc(25px + 32px + 64px + 18.75px + 56px + 200px + 187px + 109px);
  left: calc(10% + 96px);
  transform: translateX(-50%);
`;

export const BigCenteredVerticalLine = styled.div`
  width: 1px;
  height: 1835px;
  border-left: 1px solid rgba(218, 165, 32, 1);
  position: absolute;
  top: calc(665px + 200px + 187px + 60px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

export const QualiteDiv = styled.div`
  position: absolute;
  width: 30%;
  height: 90px;
  top: calc(882px + 187px + 60px + 30px);
  left: 10%;
  padding: 32px;
  gap: 8px;
  border-radius: 40px;
  background-color: rgba(23, 23, 23, 1);

`;

export const ExpertiseDiv = styled.div`
  position: absolute;
  width: 30%;
  height: 90px;
  top: calc(882px + 80px + 185px + 187px + 60px + 30px);
  right: 10%;
  padding: 32px;
  gap: 8px;
  border-radius: 40px;
  background-color: rgba(23, 23, 23, 1);
`;

export const PhilosophieDiv = styled.div`
  position: absolute;
  width: 30%;
  height: 80px;
  top: calc(1147px + 80px + 185px + 187px + 60px + 50px);
  left: 10%;
  padding: 32px;
  gap: 8px;
  border-radius: 40px;
  background-color: rgba(23, 23, 23, 1);
`;
export const ConfianceDiv = styled.div`
  position: absolute;
  width: 30%;
  height:100px;
  top: calc(1442px + 80px + 185px + 187px + 60px + 50px);
  right: 10%;
  padding: 32px;
  gap: 8px;
  border-radius: 40px;
  background-color: rgba(23, 23, 23, 1);
`;

export const WhiteText = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 18.75px;
  color: rgba(255, 255, 255, 1);
`;

export const RomanNumber  =styled.span<RomanNumberProps>`
  font-family: 'Cormorant Garamond', serif;
  font-weight: 500;
  font-size: 64px;
  line-height: 77.5px;
  color: rgba(218, 165, 32, 1);
  ${props => props.align === 'left' ? 'left: 35%;' : 'right: 35%;'};
`;

export const CenterDot = styled.div`
  position: absolute;
  font-size: 60px;
  left: 49.8%;
  transform: translateX(-50%);
  color: rgba(218, 165, 32, 1);
  opacity: 1;
  margin-top: 15px;
`;

export const DivContact = styled.div`
  position: absolute;
  width: 80%;
  height: 700px;
  top: calc(2000px + 187px + 60px);
  left: 10%;
  gap: 8px;
  border-radius: 40px;
  background-color: rgba(23, 23, 23, 1);
`;

export const DivContactLeft = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: 50%;
  border-radius: 40px 0 0 40px;
  background-image: url(${fondContactImage});
  background-size: cover;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 40px 0 0 40px;
  }
`;

export const FormContact = styled.div`
  position: absolute;
  top: 10%;
  width: 50%;
  right: 10%;
  height: 78%;
  border-radius: 40px;
  background-color: rgba(11, 12, 12, 1);
  z-index: 2;
`;
export const FormContactForm = styled.form`
  position: absolute;
  top: 10%;
  width: 80%;
  left: 10%;
`;

export const FormText = styled.text`
  font-family: 'Roboto', serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  color: rgba(233, 234, 236, 1);
  margin-bottom: 8px;
  width: 90%;
  display: inline-block;
`;
export const TitleContact = styled.h2`
  position: absolute;
  font-family: 'Cormorant Garamond', serif;
  color: rgba(255, 255, 255, 1);
  font-size: 64px;
  font-weight: 400;
  line-height: 77.5px;
  text-align: left;
  top:5%;
  left : 15%;
  width: 50%;
`;
export const FormInput = styled.input`
  font-family: 'Roboto', serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  color: rgba(233, 234, 236, 1);
  margin-bottom: 24px;
  width: 90%;
  display: inline-block;
  padding: 16px;
  background-color: rgba(34, 34, 34, 1);
  border-radius: 24px;
  border-color: transparent;
`;

export const FormTextarea = styled.textarea`
  font-family: 'Roboto', serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  color: rgba(233, 234, 236, 1);
  margin-bottom: 24px;
  width: 90%;
  height: 80px;
  display: inline-block;
  padding: 16px;
  background-color: rgba(34, 34, 34, 1); 
  border-color: transparent;
  border-radius: 24px; 
  resize: none; 
`;
export const ContactDetails = styled.h3`
  position: absolute;
  font-family: 'Cormorant Garamond',serif;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-weight: 700;
  line-height: 29.06px;
  text-align: left;
  top:50%;
  left : 15%;
  width: 50%;
`;
export const Details = styled.div`
  position: absolute;
  font-family: 'Roboto', serif;
  font-size: 16px;
  font-weight: 100;
  line-height: 18.75px;
  text-align: left;
  width: 60%;
  top: 60%;
  left: 15%;
`;
export const SendButton = styled.button`
  width: 24%;
  height: 35px;
  position: relative;
  border-radius: 40px;
  border: 1px solid rgba(233, 234, 236, 1);
  background-color: transparent;
  color: rgba(233, 234, 236, 1);
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-size: 16px;
  line-height: 18.75px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 40%;
  margin-top: 8px;

  &:hover {
    background-color: rgba(218, 165, 32, 1);
    color: black;
    border: 1px solid black;
    font-weight: 400;
  }
`;
