import styled from 'styled-components'

export const NavbarContainer = styled.div`
  position: fixed;  
  top: 48px; 
  right: 10%;
  width: 250px;  /*350 pour 3*/
  height: 56px;
  background: rgba(255, 255, 255, 1);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  padding-right: 8px;
  padding-left: 8px;
  z-index: 10;
  &.relative {
    position: relative;  /* Switch to relative when scrolled */
  }
`

export const TextContainer = styled.div`
  position: fixed;  
  top: 48px; 
  left: 24px;
  z-index: 1000;
`;

export const SecureFlow = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: rgba(233, 234, 236, 1);
`;

export const Initiative = styled.div`
  font-family: 'Cormorant Garamond', serif;
  font-weight: 500;
  font-size: 24px;
  color: rgba(218, 165, 32, 1);
`;
