import React, { useContext, useState } from 'react';
import {
    AppBar,
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation } from 'react-router-dom';
import { Initiative, SecureFlow, TextContainer } from "../css/NavbarMobile.style";
// @ts-ignore
import backgroundImage from '../../assets/backgroundHome.png';
// @ts-ignore
import { useIntl } from "react-intl";
// @ts-ignore
import frenchFlag from '../../assets/france.png';
// @ts-ignore
import englishFlag from '../../assets/royaume-uni.png';
import { LocaleContext } from "../../context/LocaleContext";

const NavbarMobile: React.FC = () => {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const localeContext = useContext(LocaleContext);
    const intl = useIntl();
    const { formatMessage } = intl;

    if (!localeContext) {
        throw new Error('LocaleContext must be used within a LocaleProvider');
    }

    const { changeLocale } = localeContext;

    const handleLanguageChange = (newLocale: string) => {
        changeLocale(newLocale);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const isActive = (path: string) => {
        return location.pathname === path;
    };

    return (
        <div>
            <AppBar position="fixed" sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
                <Toolbar>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <TextContainer>
                            <SecureFlow>Secure Flow</SecureFlow>
                            <Initiative>I N I T I A T I V E</Initiative>
                        </TextContainer>
                    </Box>
                    <IconButton color="inherit" aria-label="menu" onClick={handleDrawerOpen} sx={{ marginTop: "55px" }}>
                        <MenuIcon sx={{ color: "white", width: "24px" }} />
                    </IconButton>
                    <Box style={{ position: 'fixed', top: '20px', right: '20px', display: 'flex', alignItems: 'center', zIndex: "1" }}>
                        <Tooltip title="Français">
                            <img
                                src={frenchFlag}
                                alt="French"
                                style={{ width: '32px', height: '20px', cursor: 'pointer', marginRight: '10px' }}
                                onClick={() => handleLanguageChange('fr')}
                            />
                        </Tooltip>
                        <Tooltip title="English">
                            <img
                                src={englishFlag}
                                alt="English"
                                style={{ width: '32px', height: '20px', cursor: 'pointer' }}
                                onClick={() => handleLanguageChange('en')}
                            />
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
                <Box
                    sx={{
                        width: '100vh',
                        height: '100vh',
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                    role="presentation"
                    onClick={handleDrawerClose}
                    onKeyDown={handleDrawerClose}
                >
                    <IconButton onClick={handleDrawerClose} sx={{ color: "rgba(233, 234, 236, 1)" }}>
                        <CloseIcon sx={{ width: "24px" }} />
                    </IconButton>
                    <List>
                        {[formatMessage({ id: 'navbar.intro' }), formatMessage({ id: 'navbar.services' }), formatMessage({ id: 'navbar.team' }), formatMessage({ id: 'navbar.contact' })].map((text, index) => (
                            <ListItem
                                component={Link}
                                to={
                                    text === "Intro"
                                        ? "/"
                                        : text === "Contact"
                                            ? "/#contact"
                                            : text === "Equipe" ? "/team" : `/${text.toLowerCase()}`
                                }
                                key={text}
                                sx={{
                                    marginBottom: index !== 3 ? '32px' : '0',
                                    paddingLeft: '40px',
                                    marginTop: "40px",
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography
                                            sx={{
                                                fontFamily: 'Roboto',
                                                fontSize: '64px',
                                                fontWeight: 500,
                                                lineHeight: '75px',
                                                textAlign: 'left',
                                                color: "rgba(233, 234, 236, 1)",
                                                borderBottom: isActive(text === "Intro" ? "/" : `/${text.toLowerCase()}`)
                                                    ? '3px solid rgba(218, 165, 32, 1)'
                                                    : 'none',
                                                display: 'inline-block'
                                            }}
                                        >
                                            {text}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </div>
    )
}

export default NavbarMobile;
