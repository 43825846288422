import styled from "styled-components";
// @ts-ignore
import backgroundImage from '../../assets/backgroundHome.png';

export const Background = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
`;

export const ContentContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  top: 176px; 
  left:5%;
  right:5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  height: 100%;
`;

export const SecureDreamsText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 46.88px;
  color: rgba(255, 255, 255, 1);
`;

export const CompactDescriptionText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 18.75px;
  color: rgba(255, 255, 255, 1);
  margin-top: 32px;
`;

export const DivBackground = styled.div`
  width: calc(100% - 64px);
  border-radius: 40px;
  background-color: rgba(23, 23, 23, 1);
  padding: 32px;
  margin-top: 56px;
`;

export const TitleUpercase = styled.div`
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 29.06px;
  color: rgba(233, 234, 236, 1);
`;

export const DescriptionTextRight = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 18.75px;
  color: rgba(255, 255, 255, 1);
`;

export const CompactWhiteButton = styled.button`
  background: transparent;
  color: rgba(233, 234, 236, 1);
  border: 1px solid rgba(233, 234, 236, 1);
  border-radius: 40px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 56px; 
  width:100%;
  &:hover {
    background-color: rgba(233, 234, 236, 0.1);
  }
`;

export const LayoutText = styled.div`
  width: 90%;
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 46.88px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin-top: 72px;
  padding-left: 10%;
`;

export const RomanNumber = styled.span`
  font-family: 'Cormorant Garamond', serif;
  font-weight: 500;
  font-size: 64px;
  line-height: 77.5px;
  color: rgba(218, 165, 32, 1);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px; 
`;

export const ContentContainerContact = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 61px;
  background-color: rgba(23, 23, 23, 1);
  padding-bottom: 64px;
  border-radius: 10px;
;
`;

export const TitleContact = styled.div`
  font-family: 'Cormorant Garamond', serif;
  color: rgba(255, 255, 255, 1);
  font-size: 40px;
  font-weight: 400;
  line-height: 48.4px;
  margin-top: 40px;
  width: 45%;
  left:5%;
  position: relative;
  margin-bottom: 32px;
`;

export const FormContact = styled.div`
  position: relative;
  width: calc(90% - 64px);
  right: 5%;
  left: 5%;
  border-radius: 40px;
  background-color: rgba(11, 12, 12, 1);
  padding: 32px;
  padding-bottom: 64px;
  margin-bottom: 40px;
`;
export const FormContactForm = styled.form`
  position: relative;
`;

export const FormText = styled.text`
  font-family: 'Roboto', serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  color: rgba(233, 234, 236, 1);
  margin-bottom: 24px;
  width: 90%;
  display: inline-block;
`;

export const FormInput = styled.input`
  font-family: 'Roboto', serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  color: rgba(233, 234, 236, 1);
  margin-bottom: 24px;
  width: 90%;
  display: inline-block;
  padding: 8px;
  background-color: rgba(34, 34, 34, 1);
  border-radius: 24px;
  border-color: transparent;
`;

export const FormTextarea = styled.textarea`
  font-family: 'Roboto', serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  color: rgba(233, 234, 236, 1);
  margin-bottom: 24px;
  width: 90%;
  height: 100px;
  display: inline-block;
  padding: 8px;
  background-color: rgba(34, 34, 34, 1); 
  border-color: transparent;
  border-radius: 24px; 
`;

export const SendButton = styled.button`
  width: 90%;
  height: 35px;
  position: relative;
  border-radius: 40px;
  border: 1px solid rgba(233, 234, 236, 1);
  background-color: transparent;
  color: rgba(233, 234, 236, 1);
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 18.75px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(233, 234, 236, 0.1);
  }
`;
export const Details = styled.div`
  position: relative;
  font-family: 'Roboto', serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 18.75px;
  text-align: left;
  width: 90%;
  left:5%;
  color : rgba(233, 234, 236, 1);
`;