import React, {createContext, FC, ReactNode, useState} from 'react';

interface LocaleContextProps {
    locale: string;
    changeLocale: (newLocale: string) => void;
}

export const LocaleContext = createContext<LocaleContextProps | undefined>(undefined);

interface LocaleProviderProps {
    children: ReactNode;
}

export const LocaleProvider: FC<LocaleProviderProps> = ({children}) => {
    const [locale, setLocale] = useState<string>('fr');

    const changeLocale = (newLocale: string) => {
        setLocale(newLocale);
        localStorage.setItem('locale', newLocale);
    };

    return (
        <LocaleContext.Provider value={{locale, changeLocale}}>
            {children}
        </LocaleContext.Provider>
    );
};
