import { Box, Typography } from "@mui/material";
import { Background } from "../css/Home.style";
import Navbar from "../components/Navbar";

const Conditions = () => {
  return (
    <Box sx={{padding: "20px", margin: "0", color: "white", overflow: "hidden", position: "relative" }}>
      <Background />
      <Navbar selectedNavItem="Contact" />
      <Box style={{top: "120px", position: "relative"}}>
        <Typography variant="h4" gutterBottom >
            Conditions générales de vente
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 1 - Champ d’application
        </Typography>
        <Typography paragraph>
            Les présentes Conditions générales de vente constituent, conformément à l’article L. 441-1 du code du commerce, le socle de la relation commerciale entre les parties.
            Elles ont pour objet de définir les conditions dans lesquelles la SAS SECURITYFLOW-INITIATIVE (« Le Prestataire ») fournit aux personnes physiques ou morales (« Les Clients ou le Client ») qui lui en font la demande, via le site internet du Prestataire, par contact direct ou via un support papier, les Services suivants : (Liste des moyens de nous contacter avec les adresses des réseaux sociaux...) (« les Services »).
            Elles s’appliquent, sans restriction ni réserve, à tous les Services rendus par le Prestataire auprès des Clients de même catégorie, quelles que soient les clauses pouvant figurer sur les documents du Client, et notamment ses conditions générales d’achat...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 2 - Commandes
        </Typography>
        <Typography variant="h6" gutterBottom>
            2.1 - Conditions générales
        </Typography>
        <Typography paragraph>
            Les ventes de Services ne sont parfaites qu’après établissement d’un devis et acceptation expresse et par écrit de la commande du Client par le Prestataire, matérialisée par un accusé de réception émanant du Prestataire et acceptation du devis...
        </Typography>
        
        <Typography variant="h6" gutterBottom>
            2.2 - Modifications
        </Typography>
        <Typography paragraph>
            Les éventuelles modifications de la commande demandées par le Client ne seront prises en compte, dans la limite des possibilités du Prestataire, que si elles sont notifiées par écrit...
        </Typography>

        <Typography variant="h6" gutterBottom>
            2.3 - Accompte
        </Typography>
        <Typography paragraph>
            En cas d’annulation de la commande par le Client après son acceptation par le Prestataire moins de ...(nombre) jours avant la date prévue pour la Fourniture des Services commandés, pour quelque raison que ce soit hormis la force majeure...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 3 - Tarifs
        </Typography>
        <Typography paragraph>
            Les prestations de Services sont fournies aux tarifs du Prestataire en vigueur au jour de la passation de la commande, selon ...(le barème du Prestataire/le devis préalablement établi par le Prestataire et accepté par le Client), comme indiqué à l’article « Commandes » ci-dessus...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 4 - Conditions de règlement
        </Typography>
        <Typography variant="h6" gutterBottom>
            4.1 - Délais de règlement
        </Typography>
        <Typography paragraph>
            Le prix est payable comptant, en totalité au jour de la Fourniture des Services commandés, dans les conditions définies à l’article « Modalités de Fourniture des Services »...
        </Typography>

        <Typography variant="h6" gutterBottom>
            4.2 - Pénalités de retard
        </Typography>
        <Typography paragraph>
            Des pénalités de retard sont dues dans le cas de paiement après les délais de paiement applicables...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 5 - Modalités de Fourniture des Services
        </Typography>
        <Typography paragraph>
            Les Services demandés par le Client seront fournis dans un délai maximum de ...(délai) à compter de la réception par le Prestataire du bon de commande correspondant dûment signé...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 6 - Responsabilité du Prestataire - Garantie
        </Typography>
        <Typography paragraph>
            Le Prestataire garantit, conformément aux dispositions légales, le Client, contre tout défaut de conformité des Services et tout vice caché, provenant d’un défaut de conception ou de Fourniture desdits Services à l’exclusion de toute négligence ou faute du Client...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 7 - Droit de propriété intellectuelle
        </Typography>
        <Typography paragraph>
            Le Prestataire reste propriétaire de tous les droits de propriété intellectuelle sur les études, dessins, modèles, prototypes, etc., réalisés (même à la demande du Client) en vue de la Fourniture des Services au Client...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 8 - Données personnelles
        </Typography>
        <Typography paragraph>
            Les données personnelles recueillies auprès des Clients font l’objet d’un traitement informatique réalisé par le Fournisseur...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 9 - Imprévision
        </Typography>
        <Typography paragraph>
            Dans l’hypothèse où le Prestataire ou le Client souhaiterait soulever, dans le cadre de la Fourniture de Services du Prestataire au Client soumise aux présentes Conditions générales de vente, un cas d’imprévision telle que définie par l’article 1195 du code civil...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 10 - Exécution forcée en nature
        </Typography>
        <Typography paragraph>
            En cas de manquement de l’une ou l’autre des Parties à ses obligations, la Partie victime de la défaillance dispose du droit de requérir l’exécution forcée en nature des obligations découlant des présentes...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 11 - Exception d’inexécution
        </Typography>
        <Typography paragraph>
            Il est rappelé qu’en application de l’article 1219 du code civil, chaque Partie pourra refuser d’exécuter son obligation, alors même que celle-ci est exigible, si l’autre Partie n’exécute pas la sienne et si cette inexécution est suffisamment grave...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 12 - Force majeure
        </Typography>
        <Typography paragraph>
            Les Parties ne pourront être tenues pour responsables si la non-exécution ou le retard dans l’exécution de l’une quelconque de leurs obligations, telles que décrites dans les présentes découle d’un cas de force majeure, au sens de l’article 1218 du code civil...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 13 - Résolution du contrat
        </Typography>
        <Typography paragraph>
            La résolution pour l’impossibilité de l’exécution d’une obligation devenue excessivement onéreuse ne pourra, nonobstant la clause Résolution pour manquement d’une partie à ses obligations figurant ci-après, intervenir que ...(nombre) jours après ...(l’envoi/la réception)...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 14 - Litiges
        </Typography>
        <Typography paragraph>
            En vue de trouver ensemble une solution à tout litige qui surviendrait dans l’exécution du présent contrat, les contractants conviennent de se réunir dans les ...(délai)...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 15 - Clause d’arbitrage
        </Typography>
        <Typography paragraph>
            Tous les litiges pouvant survenir entre les Parties concernant le présent contrat, sa validité, son interprétation, son exécution ou sa résolution, seront soumis à une procédure d’arbitrage dans les conditions suivantes...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 16 - Langue du contrat – Droit applicable
        </Typography>
        <Typography paragraph>
            Les présentes Conditions générales et les opérations qui en découlent sont régies exclusivement par le droit français...
        </Typography>

        <Typography variant="h5" gutterBottom>
            Article 17 - Acceptation du Client
        </Typography>
        <Typography paragraph>
            Les présentes Conditions générales de vente sont expressément agréées et acceptées par le Client, qui déclare et reconnaît en avoir une parfaite connaissance...
        </Typography>
      </Box>
    </Box>
  );
};

export default Conditions;
