import styled from 'styled-components'

export const ClickableText = styled.a`
  font-family: 'Cormorant Garamond', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.38px;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
  display: block; /* Ensures each link is on a new line */
  margin-bottom: 10px; /* Adds spacing between links */

  &:hover {
    color: rgba(218, 165, 32, 1);
  }
`;

export const FooterText = styled.footer`
  background-color: transparent;
  position: relative;
`;

export const FooterContainer = styled.footer`
  background-color: transparent;
  padding-bottom: 20px;
  padding-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
`;

export const LinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
`;

export const HorizontalBar = styled.div`
  width: 60%;
  height: 1px;
  background-color: rgba(218, 165, 32, 1);
  margin-bottom: 20px; /* Adds spacing between the bar and social icons */
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Adds spacing between the icons and copyright text */

  svg {
    margin: 0 10px;
    fill: #FFFFFF;
    width: 24px;
    height: 24px;
  }
`;

export const CopyrightText = styled.p`
  font-family: 'Cormorant Garamond', serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-align: center;

  a {
    color: rgba(205, 126, 50, 1);
    cursor: pointer;
    text-decoration: none;
  }
`;
