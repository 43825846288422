import NavbarMobile from "../components/NavbarMobile";
import FooterMobile from "../components/FooterMobile";
import { Background, ContentContainer } from "../css/HomeMobile.style";
import {
    MemberCard,
    MemberImage,
    MemberInfo,
    MemberName,
    MemberRole,
    TeamContainer,
    TitleText
} from "../css/TeamMobile.style";
// @ts-ignore
import marius from "../../assets/marius.png";
// @ts-ignore
import killian from "../../assets/kylian.png";
// @ts-ignore
import mathis from "../../assets/mathis.png";
// @ts-ignore
import yani from "../../assets/yani.png";
// @ts-ignore
import gurvan from "../../assets/gurvan.png";
// @ts-ignore
import theophile from "../../assets/theophile.png";
// @ts-ignore
import eythan from "../../assets/eythan.jpeg"
// @ts-ignore
import romain from "../../assets/romain.jpeg"
// @ts-ignore
import ilona from "../../assets/ilona.png";
// @ts-ignore
import LinkedinIcon from '@mui/icons-material/LinkedIn';
import { OrangeText } from "../css/Home.style";
// @ts-ignore
import { useIntl } from "react-intl";

const handleLinkedinLink = (url: string) => {
    window.open(url, "_blank");
};

const Team: React.FC = () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const teamMembers = [
        {
            name: "Marius Delahay",
            role: formatMessage({ id: 'team.marius.job' }),
            image: marius,
            linkedin: "https://www.linkedin.com/in/delahay-marius/",
        },
        {
            name: "Ilona Boizard",
            role: formatMessage({ id: 'team.ilona.job' }),
            image: ilona,
            linkedin: "https://www.linkedin.com/in/ilona-boizard/",
        },
        {
            name: "Killian Mannarelli",
            role: formatMessage({ id: 'team.killian.job' }),
            image: killian,
            linkedin: "https://www.linkedin.com/in/killian-mannarelli/",
        },
        {
            name: "Théophile Laborde",
            role: formatMessage({ id: 'team.theophile.job' }),
            image: theophile,
            linkedin: "https://www.linkedin.com/in/théophile-laborde-b7067a324/",
        },
        {
            name: "Yani Madebos",
            role: formatMessage({ id: 'team.yani.job' }),
            image: yani,
            linkedin: "https://www.linkedin.com/in/yani-madebos/",
        },
        {
            name: "Mathis Tamarelle",
            role: formatMessage({ id: 'team.mathis.job' }),
            image: mathis,
            linkedin: "https://www.linkedin.com/in/mathis-tamarelle/",
        },
        {
            name: "Gurvan Dumarchat",
            role: formatMessage({ id: 'team.gurvan.job' }),
            image: gurvan,
            linkedin: "https://www.linkedin.com/in/gurvan-dumarchat-957434303/",
        },
        {
            name: "Eythan Boizard",
            role: formatMessage({ id: 'team.killian.job' }),
            image: eythan,
            linkedin: "https://www.linkedin.com/in/eythan-boizard-7ab819209",
        },
        {
            name: "Romain Guichot",
            role: formatMessage({ id: 'team.yani.job' }),
            image: romain,
            linkedin: "https://www.linkedin.com/in/romain-guichot-a198391b1/",
        },
    ];

    return (
        <Background>
            <NavbarMobile />
            <ContentContainer style={{ alignItems: "center" }}>
                <TitleText>OUR TEAM<OrangeText>.</OrangeText></TitleText>
                <TeamContainer style={{ flexDirection: "column" }}>
                    {teamMembers.map((member, index) => (
                        <MemberCard key={index}>
                            <MemberImage src={member.image} alt={member.name} />
                            <MemberInfo>
                                <MemberName>{member.name}</MemberName>
                                <MemberRole>{member.role}</MemberRole>
                                <LinkedinIcon sx={{
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                    color: 'rgba(255, 255, 255, 1)'
                                }} onClick={() => handleLinkedinLink(member.linkedin)} />
                            </MemberInfo>
                        </MemberCard>
                    ))}
                </TeamContainer>
                <FooterMobile />
            </ContentContainer>
        </Background>
    );
};

export default Team;
