import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {LocaleProvider} from "./context/LocaleContext";

library.add(fas, fab)


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <LocaleProvider>
            <App/>
        </LocaleProvider>
    </React.StrictMode>
)
