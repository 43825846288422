import Navbar from '../components/Navbar'
import {Background, Content, HomeContainer, YellowText} from '../css/Home.style'
import Footer from "../components/Footer"
import {
    AskForOne,
    Container,
    Description,
    Image,
    ImageContainer,
    Section,
    TextContainer,
    Title
} from "../css/Services.style";
// @ts-ignore
import devSecOps from "../../assets/DevSecOps.png"
// @ts-ignore
import penTest from "../../assets/PTest.png"
// @ts-ignore
import sast from "../../assets/Sast.png"
// @ts-ignore
import arrow from "../../assets/arrow.png"
// @ts-ignore
import engineer from "../../assets/Engineering.png"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {TitleTeam} from "../css/Team.styles"
// @ts-ignore
import {useIntl} from "react-intl";

const Services: React.FC = () => {
    const { formatMessage } = useIntl();

    const data = [
        {
            title: formatMessage({ id: 'services.security.title' }),
            description: formatMessage({ id: 'services.security.text' }),
            image: devSecOps,
        },
        {
            title: formatMessage({ id: 'services.development.title' }),
            description: formatMessage({ id: 'services.development.text' }),
            image: penTest,
        },
        {
            title: formatMessage({ id: 'services.experience.title' }),
            description: formatMessage({ id: 'services.experience.text' }),
            image: sast,
        },
        {
            title: formatMessage({ id: 'services.packaging.title' }),
            description: formatMessage({ id: 'services.packaging.text' }),
            image: engineer,
        },
    ];

    const handleBackClick = () => {
        window.location.replace("/")
    };

    return (
        <HomeContainer>
            <Background/>
            <Navbar selectedNavItem="Services"/>
            <Content style={{overflowX: "hidden"}}>
                <Image src={arrow} alt={"arrow"} style={{
                    left: "15%",
                    position: "absolute",
                    top: "220px",
                    cursor: "pointer",
                    zIndex: 1,
                    width: "56px",
                }}
                       onClick={handleBackClick}/>
                <TitleTeam>{formatMessage({ id: 'services.title' })}<YellowText>.</YellowText></TitleTeam>
                <Container>
                    {data.map((item, index) => (
                        <Section key={index} reverse={index % 2 !== 0}>
                            <ImageContainer>
                                <Image src={item.image} alt={item.title}/>
                            </ImageContainer>
                            <TextContainer>
                                <Title>{item.title}<YellowText>.</YellowText></Title>
                                <Description>{item.description}</Description>
                                <AskForOne onClick={() => {
                                    window.location.replace("/#contact")
                                }}>{formatMessage({ id: 'services.button' })}
                                    <ArrowForwardIcon sx={{
                                        color: "rgba(205, 126, 50, 1)",
                                        position: "relative",
                                        marginLeft: "5px",
                                        marginTop: "5px",
                                        top: "7px",
                                        animation: 'moveRightLeft 2s infinite',
                                        '@keyframes moveRightLeft': {
                                            '0%': {
                                                transform: 'translateX(16px)',
                                            },
                                            '50%': {
                                                transform: 'translateX(8px)',
                                            },
                                            '100%': {
                                                transform: 'translateX(16px)',
                                            },
                                        },
                                    }}/>
                                </AskForOne>
                            </TextContainer>
                        </Section>
                    ))}
                </Container>
                <div style={{top: "1750px", position: "relative", width: "100%"}}>
                    <Footer/>
                </div>
            </Content>
        </HomeContainer>
    );
};

export default Services;
