import {Box, Typography} from "@mui/material"
// @ts-ignore
import logo from '../../assets/500.png'
import {Background} from '../css/Home.style'
import theme from "../components/Theme"
import Navbar from "../components/Navbar";

const Page500 = () => {
    return <Background>
        <Navbar selectedNavItem=""/>
        <Box sx={{textItems: "center", display: "flex", justifyContent: "center", marginTop: "100px"}}>
            <img src={logo} alt="403 Forbidden" style={{width: '500px'}}/>
        </Box>
        <Typography variant="h4" component="h1" gutterBottom color={theme.palette.secondary.main} sx={{
            textItems: "center", display: "flex", justifyContent: "center",
            fontFamily: 'Cormorant Garamond',
            fontWeight: 300
        }}>
            Internal server error
        </Typography>
    </Background>
}
export default Page500