import React, {ReactNode, useContext} from 'react';
import { IntlProvider } from 'react-intl';
import messages_en from './i18n/en.json';
import messages_fr from './i18n/fr.json';
import {LocaleContext} from "./context/LocaleContext";

const messages: { [key: string]: Record<string, string> } = {
    en: messages_en,
    fr: messages_fr,
};

interface IntlManagerProps {
    children: ReactNode;
}

export const IntlManager: React.FC<IntlManagerProps> = ({ children }) => {
    const localeContext = useContext(LocaleContext);

    if (!localeContext) {
        throw new Error('LocaleContext must be used within a LocaleProvider');
    }

    const { locale } = localeContext;
    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            {children}
        </IntlProvider>
    );
};
