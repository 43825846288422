import React from 'react';
import {
    ClickableText,
    CopyrightText,
    FooterContainer,
    HorizontalBar,
    LinksContainer,
} from "../css/Footer.style";
// @ts-ignore
import { useIntl } from 'react-intl';

const Footer: React.FC = () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    return (
        <FooterContainer>
            <LinksContainer>
                <ClickableText href="/services/">{formatMessage({ id: 'footer.text.services' })}</ClickableText>
                <ClickableText href="/#contact">{formatMessage({ id: 'footer.text.contact' })}</ClickableText>
                <ClickableText href="/conditions">{formatMessage({ id: 'footer.text.cgv' })}</ClickableText>
            </LinksContainer>
            <HorizontalBar />
            <CopyrightText>
                &copy; Copyright Secure Flow Initiative 2024
            </CopyrightText>
        </FooterContainer>
    );
};

export default Footer;