import styled from 'styled-components';

export const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 56px;
  width: 100%;
`;

export const ServiceImage = styled.img`
    width: 90%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

export const ServiceTextContainer = styled.div`
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    padding: 15px;
`;

export const ServiceTitle = styled.h2`
  width: 100%;               // Ensures the title spans the full width of its container
  font-size: 32px;           // Adjust if necessary
  font-family: "Cormorant Garamond", sans-serif;
  color: #FFFFFF;            // Sets text color to white
  text-align: left;          // Aligns the text to the left
  padding-left: 20px;        // Adds padding to the left to keep the text from the edge
  margin: 0 0 20px 0;        // Adds space below the title
`;
export const ServiceDescription = styled.p`
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 16px;
  color: #FFFFFF;
`;

export const AskForOneButton = styled.a`
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;

    svg {
        margin-left: 5px;
    }
`;

export const TitleText = styled.h1`
  font-size: 32px;      // Adjust font size as necessary
  color: #FFFFFF;       // White color for text
  text-align: left;     // Aligns the text to the left
  padding-left: 20px;   // Adds padding to ensure text is not flush with the screen edge
  font-family: "Cormorant Garamond", sans-serif; // Ensures font consistency
  margin-bottom: 20px;  // Adds a bottom margin for spacing between title and content
`;