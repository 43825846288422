import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Initiative, NavbarContainer, NavItem, SecureFlow, TextContainer } from '../css/Navbar.style';
import { Box, Tooltip } from "@mui/material";
import { LocaleContext } from "../../context/LocaleContext";
// @ts-ignore
import { useIntl } from 'react-intl';
// @ts-ignore
import frenchFlag from '../../assets/france.png';
// @ts-ignore
import englishFlag from '../../assets/royaume-uni.png';

interface NavbarProps {
    selectedNavItem: string;
}

const Navbar: React.FC<NavbarProps> = ({ selectedNavItem }) => {
    const localeContext = useContext(LocaleContext);
    const intl = useIntl();
    const { formatMessage } = intl;

    if (!localeContext) {
        throw new Error('LocaleContext must be used within a LocaleProvider');
    }

    const { changeLocale } = localeContext;

    const handleLanguageChange = (newLocale: string) => {
        changeLocale(newLocale);
    };

    return (
        <>
            <TextContainer onClick={() => window.location.replace("/")} style={{ cursor: 'pointer' }}>
                <SecureFlow>Secure Flow</SecureFlow>
                <Initiative>I N I T I A T I V E</Initiative>
            </TextContainer>
            <NavbarContainer>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <NavItem selected={selectedNavItem === 'Intro'}>{formatMessage({ id: 'navbar.intro' })}</NavItem>
                </Link>
                <Link to="/services" style={{ textDecoration: 'none' }}>
                    <NavItem selected={selectedNavItem === 'Services'}>{formatMessage({ id: 'navbar.services' })}</NavItem>
                </Link>
                <Link to="/team" style={{ textDecoration: 'none' }}>
                    <NavItem selected={selectedNavItem === 'Team'}>{formatMessage({ id: 'navbar.team' })}</NavItem>
                </Link>
                <Link to="/#contact" style={{ textDecoration: 'none' }}>
                    <NavItem selected={selectedNavItem === 'Contact'}>{formatMessage({ id: 'navbar.contact' })}</NavItem>
                </Link>
            </NavbarContainer>
            <Box style={{ position: 'fixed', top: '20px', right: '20px', display: 'flex', alignItems: 'center', zIndex: "1" }}>
                <Tooltip title="Français">
                    <img
                        src={frenchFlag}
                        alt="French"
                        style={{ width: '32px', height: '20px', cursor: 'pointer', marginRight: '10px' }}
                        onClick={() => handleLanguageChange('fr')}
                    />
                </Tooltip>
                <Tooltip title="English">
                    <img
                        src={englishFlag}
                        alt="English"
                        style={{ width: '32px', height: '20px', cursor: 'pointer' }}
                        onClick={() => handleLanguageChange('en')}
                    />
                </Tooltip>
            </Box>
        </>
    );
};

export default Navbar;
