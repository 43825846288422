import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
    palette: {
        primary: {
            main:  'rgba(218, 165, 32, 1)',
        },
        secondary: {
            main: '#fff',
        },
        background: {
            default: '#000',
        },
        text: {
            primary: 'rgba(23, 23, 23, 1)',
            secondary: 'rgba(233, 234, 236, 1)',
        },
    },
});

export default theme;
