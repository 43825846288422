import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";

import Home from './ui/pages/Home';
import Services from "./ui/pages/Services";
import Projects from "./ui/pages/Projects";
import SignUp from "./ui/pages/SignUp";
import Team from "./ui/pages/Team";
import Page500 from "./ui/pages/500";
import Page403 from "./ui/pages/403";
import Page404 from "./ui/pages/404";

// Pages Mobile
import ServicesMobile from "./ui/mobilePages/Services";
import TeamMobile from "./ui/mobilePages/Team";
import HomeMobile from "./ui/mobilePages/Home";

// Thème et Contexts
import theme from "./ui/components/Theme";
import { IntlManager } from "./IntlManager";
import {LocaleProvider } from "./context/LocaleContext";

// CSS global
import './ui/css/style.css';
import Conditions from './ui/pages/Conditions';

const App: React.FC = () => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <LocaleProvider>
            <IntlManager>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Routes>
                            {isDesktop ? (
                                <>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/services" element={<Services />} />
                                    <Route path="/team" element={<Team />} />
                                    <Route path="/projects" element={<Projects />} />
                                    <Route path="/signup" element={<SignUp />} />
                                    <Route path="/conditions" element={<Conditions />} />
                                    <Route path="500" element={<Page500 />} />
                                    <Route path="403" element={<Page403 />} />
                                    <Route path="*" element={<Page404 />} />
                                </>
                            ) : (
                                <>
                                    <Route path="/" element={<HomeMobile />} />
                                    <Route path="/services" element={<ServicesMobile />} />
                                    <Route path="/team" element={<TeamMobile />} />
                                    <Route path="/conditions" element={<Conditions />} />
                                    <Route path="500" element={<Page500 />} />
                                    <Route path="403" element={<Page403 />} />
                                    <Route path="*" element={<HomeMobile />} />
                                </>
                            )}
                        </Routes>
                    </Router>
                </ThemeProvider>
            </IntlManager>
        </LocaleProvider>
    );
};

export default App;
