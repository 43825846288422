import Navbar from '../components/Navbar'
import { HomeContainer, Background, Content } from '../css/Home.style'

const SignUp = () => {
 return (
     <HomeContainer>
      <Background />
      <Navbar selectedNavItem="Sign up" />
      <Content>
       <h1>Sign up</h1>
       <p>Details about the SignUp offered...</p>
      </Content>
     </HomeContainer>
 );
};

export default SignUp
