import React from 'react';
import {
    ClickableText,
    CopyrightText, 
    FooterContainer,
    FooterText,
    HorizontalBar,
    LinksContainer
} from "../css/FooterMobile.style";
import { Initiative, SecureFlow } from "../css/Navbar.style";
// @ts-ignore
import { useIntl } from 'react-intl';

const FooterMobile: React.FC = () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    return (
        <FooterContainer>
            <FooterText>
                <SecureFlow style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>Secure Flow</SecureFlow>
                <Initiative>I N I T I A T I V E</Initiative>
            </FooterText>
            <LinksContainer>
                <ClickableText href="/services/">{formatMessage({ id: 'footer.text.services' })}</ClickableText>
                <ClickableText href="/#contact">{formatMessage({ id: 'footer.text.contact' })}</ClickableText>
                <ClickableText href="#">{formatMessage({ id: 'footer.text.cgv' })}</ClickableText>
            </LinksContainer>
            <HorizontalBar />
            <CopyrightText>
                &copy; Copyright <a href="/condition">Secure Flow Initiative</a> 2024
            </CopyrightText>
        </FooterContainer>
    );
}

export default FooterMobile;
